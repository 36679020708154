body {
  font-family: "Roboto", sans-serif;
}
.log-page-ctn {
}
@media (max-width: 500px) {
  .nav {
    flex-direction: column;
    padding: 20px 0;
  }
  .ctn {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
}
